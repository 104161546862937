<template>
  <div style="height: 300px">
    <div class="boxTop">
      <div class="content">{{ IntervalValues ? IntervalValues : "" }}</div>
      <div class="title">区间用量</div>
    </div>
    <div class="boxCenter">
      <span class="left">
        <el-date-picker
          v-model="startsDate"
          type="date"
          placeholder="开始时间"
          value-format="yyyy-MM-dd"
          @change="startDateChange"
        >
        </el-date-picker>
      </span>
      <span>
        <el-date-picker
          class="right"
          v-model="endsDate"
          type="date"
          placeholder="结束时间"
          value-format="yyyy-MM-dd"
          @change="endDateChange"
        >
        </el-date-picker>
      </span>
    </div>
    <div class="boxBottom">
      <el-button round type="primary" size="medium" @click="btnQueryIntervals"
        >查询</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    deviceID: {
      type: Number,
    },
  },

  data() {
    return {
      startsDate: "",
      endsDate: "",
      IntervalValues: "",
    };
  },
  methods: {
    startDateChange(val) {
      this.startsDate = val;
    },
    endDateChange(val) {
      this.endsDate = val;
    },
    btnQueryIntervals() {
      if (this.startsDate == "" || this.endsDate == "") {
        return this.$message.warning("开始时间结束时间不能为空！");
      }
      this.queryIntervals();
    },
    queryIntervals() {
      this.$axios({
        url: "/common2/energyConsumption/intervalEnergyConsumption",
        method: "POST",
        params: {
          deviceId: this.deviceID,
          // startDate: "2022-04-25",
          // endDate: "2022-04-27",
          startDate: this.startsDate,
          endDate: this.endsDate,
        },
      }).then((res) => {
        console.log(res);
        if (res.data.httpCode == 500) {
          this.IntervalValues = "";
          this.$message.warning(res.data.msg);
        }
        if (res.data.status == 200) {
          this.IntervalValues = res.data.result.IntervalValue;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.boxTop {
  text-align: center;
  margin-bottom: 30px;
}
.boxTop .content {
  color: #000000;
  font-size: 32px;
}
.boxTop .title {
  color: #b5b6b8;
  font-size: 18px;
}
.boxCenter {
  text-align: center;
}
.boxCenter .left {
  padding-right: 100px;
}
.boxBottom {
  margin-top: 80px;
  text-align: center;
}
.boxBottom .el-button--primary {
  width: 500px;
  height: 50px;
}
</style>