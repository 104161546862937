<template>
  <div>
    <div class="boxTop">
      <div class="content">
        {{ hydropowers.actualReading ? hydropowers.actualReading : "" }}
      </div>
      <div class="title">累计读数</div>
    </div>
    <div class="boxMiddle">
      <div class="letf">
        <div class="title">本月用量</div>
        <div class="content">
          {{ thisReading.Reading ? thisReading.Reading : "" }}
        </div>
      </div>
      <div class="right">
        <div class="title">上月用量</div>
        <div class="content">
          {{ latsReading.Reading ? latsReading.Reading : "" }}
        </div>
      </div>
    </div>
    <div class="boxFourth">
      <div>
        电量状态
        <span>
          {{ hydropowers.stateOfCharge ? hydropowers.stateOfCharge : "" }}
        </span>
      </div>
      <div class="title">
        更新时间
        <span> {{ hydropowers.uptime ? hydropowers.uptime : "" }} </span>
      </div>
    </div>
    <div class="boxBottom">
      <el-row>
        <el-col :span="7" align="right">
          <el-button type="info" @click="btnDialogHistorys">历史用量</el-button>
        </el-col>
        <el-col :span="7" align="right">
          <el-button type="info" @click="btnDialogSection">区间用量</el-button>
        </el-col>
         <el-col :span="7" align="right">
          <el-button type="info" @click="btnDialogDevice">设备预警</el-button>
        </el-col>
      </el-row>

      <el-dialog
        title="历史用量"
        :visible.sync="dialogHistorysVisible"
        append-to-body
      >
        <Historys :deviceID="hydropowers.id"  :ecDayData="ecDayData" :ecMonthData="ecMonthData" :ecYearData="ecYearData"></Historys>
      </el-dialog>
      <el-dialog
        title="区间用量"
        :visible.sync="dialogSectionsVisible"
        append-to-body
      >
        <Sections :deviceID="hydropowers.id"></Sections>
      </el-dialog>
      <el-dialog
        title="设备预警"
        :visible.sync="dialogDeviceVisible"
        append-to-body
      >
        <DeviceEarlWarning :deviceData="deviceData"></DeviceEarlWarning>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Historys from "../Hydropower/historys.vue";
import Sections from "../Hydropower/sections.vue";
import DeviceEarlWarning from "../Hydropower/deviceEarlWarning"
export default {
  name: "WaterMeter",
  components: {
    Historys,
    Sections,
    DeviceEarlWarning
  },
  props:["deviceData","hydropowers","latsReading","thisReading"],
  data() {
    return {
      dialogHistorysVisible: false,
      dialogSectionsVisible: false,
      dialogDeviceVisible:false,
      ecDayData:[],
      ecMonthData:[],
      ecYearData:[]
    };
  },
  methods: {
   async btnDialogHistorys() {
      this.dialogHistorysVisible = true;
      await this.queryEnergyConsumptions();
    },
    btnDialogSection() {
      this.dialogSectionsVisible = true;
    },
    btnDialogDevice(){
      this.dialogDeviceVisible =true;
    },
   async queryEnergyConsumptions() {
     let result =  await this.$axios({
        url: "/common2/energyConsumption/getEcData",
        method: "GET",
        params: {
          type: 2,
          deviceId: this.hydropowers.id,
        },
      })
      let {ecDayData, ecMonthData, ecYearData} =result.data.data;
        this.ecDayData = ecDayData;
        this.ecMonthData = ecMonthData;
        this.ecYearData =ecYearData;
    }
  },
};
</script>

<style lang="scss" scoped>
.boxTop {
  text-align: center;
  margin-bottom: 30px;
}
.boxTop .content {
  color: #000000;
  font-size: 32px;
}
.boxTop .title {
  color: #b5b6b8;
  font-size: 18px;
}
.boxMiddle {
  width: 500px;
  height: 150px;
  background-color: #7bb5fe;
  border-radius: 10px;
  margin: 0 auto;
  color: #fff;
}
.boxMiddle .letf {
  position: absolute;
  left: 160px;
  top: 200px;
}
.boxMiddle .right {
  position: absolute;
  right: 160px;
  top: 200px;
}
.boxMiddle .content {
  padding-top: 20px;
  text-align: center;
}
.boxFourth {
  margin-top: 30px;
  text-align: center;
  color: #b5b6b8;
  font-size: 18px;
}
.boxFourth .title {
  padding-top: 10px;
}
.boxBottom {
  margin-top: 30px;
}
</style>